













import {Component, Ref, Vue} from 'vue-property-decorator';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.min.css';
import { Vietnamese as VietnameseLocale } from 'flatpickr/dist/l10n/vn.js';
import moment from "moment";

@Component({
    components: {
        flatPickr
    }
})
export default class CellEditorDeliveryDate extends Vue {
    @Ref('fpRangeComponent') fpRangeComponent!: any;

    params: any = null;
    value: any = null;
    forceDeliveryDateRender: number = 0;

    configDateTimePickerRange = {
        locale: VietnameseLocale,
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        mode: 'range',
        defaultDate: [new Date(), new Date()]
    };

    created(){
        let formatType = "DD-MM-YYYY HH:mm";
        let paramsValue = this.params.value;

        let from = moment(paramsValue.from).format(formatType);
        let to = moment(paramsValue.to).format(formatType);
        this.$set(this.configDateTimePickerRange, 'defaultDate', [from, to]);

        setTimeout(() => {
            this.forceDeliveryDateRender += 1;
        }, 10);

        setTimeout(() => {
            this.fpRangeComponent.fp.open();
        }, 20);
    }

    getValue(): any {
        return this.params.value;
    }

    deliveryDateClosed(selectedDates: any, dateStr: any, instance: any) {
        let paramsValue = this.params.value;

        if (selectedDates.length == 2) {
            let start = selectedDates[0];
            let end = selectedDates[1];

            paramsValue.from = moment(start).format();
            paramsValue.to = moment(end).format();
        }
        else {
            // this.deliveryDate = null;
            selectedDates = null;

            setTimeout(() => {
                this.fpRangeComponent.fp.clear();
            }, 100);

            // paramsValue.from = null;
            // paramsValue.to = null;
        }
    }

    deliveryDateChanged(selectedDates: any, dateStr: any, instance: any){
        let paramsValue = this.params.value;

        if (selectedDates.length == 2) {
            let start = selectedDates[0];
            let end = selectedDates[1];

            paramsValue.from = moment(start).format();
            paramsValue.to = moment(end).format();
            this.params.data.isChangeDeliveryDate = true;
        }
    }
}
