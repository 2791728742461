







































































































































import {AgGridVue} from 'ag-grid-vue';
import Component from 'vue-class-component'
import {Ref, Vue, Watch} from 'vue-property-decorator';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererLink from './cell-renderer/CellRendererLink.vue';
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue';
import CellRendererActions from './cell-renderer/CellRendererActions.vue';
import CellEditorDeliveryDate from './cell-renderer/CellEditorDeliveryDate.vue';
import CustomPinnedRowRenderer from "./CustomPinnedRowRenderer.vue";
import {OrderService} from '@/views/order/OrderService';
import {SettingColumnService} from '@/services/SettingColumnService';
import draggable from 'vuedraggable'
import vSelect from 'vue-select'

import _filter from "lodash/filter";
import _map from "lodash/map";
import _each from "lodash/each";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";

import {ActionResult} from '@/models/ActionResult';
import {OrderGetByIdResult} from "@/models/order/OrderGetByIdResult";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";

import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import FilterList from "@/components/filter/FilterList.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {AttributeType} from "@/constants/Attribute";
import {DiscountType, Status, ExportType} from '@/constants/Order';
import {LoadResult} from "@/models/LoadResult";
import {formatNumber} from "@/common";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import {OptionOrderStatus} from "@/models/order/OrderSearchResult";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import OrderPrint from "@/views/order/order-print/OrderPrint.vue";
import moment from 'moment';
import ExportOptions from "@/views/order/order-list/ExportOptions.vue";
import OrderGoodsIssuedUpdateStatus from "@/views/order/order-list/OrderGoodsIssuedUpdateStatus.vue";
import {CategoryType} from '@/models/category/CategoryType';
import {UserConfigService} from "@/store/user-management/UserConfigService";
import {Getter} from "vuex-class";

// Register the router hooks with their names
// Component.registerHooks([
//     'beforeRouteEnter',
//     // 'beforeRouteLeave',
//     // 'beforeRouteUpdate'
// ]);

@Component({
    name: 'OrderList',
    components: {
        FilterList,
        SelectPageSize,
        AgGridVue,
        CellRendererLink,
        CellRendererStatus,
        CellRendererActions,
        draggable,
        vSelect,
        OrderPrint,
        SettingColumns: () => import(/* webpackChunkName: "SettingColumns" */ '@/components/SettingColumns.vue'),
        OrderDetailSideBar: () => import(/* webpackChunkName: "OrderDetailSideBar" */  '@/views/order/order-list/OrderDetailSideBar.vue'),
        ExportOptions,
        OrderGoodsIssuedUpdateStatus
    }
})

export default class OrderList extends Vue {
    @Getter("oidcUser") oidcUser!: any;

    @Ref('agGridTable') agGridTable!: any;
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Ref('orderDetailSideBar') orderDetailSideBar!: any;
    @Ref('orderPrint') orderPrint!: any;
    @Ref('exportOptions') exportOptions!: any;
    @Ref('updateStatus') updateStatus!: any;

    private orderService: OrderService;
    private settingColumnService: SettingColumnService;
    private userConfigService: UserConfigService;

    active: Boolean = false;
    searchQuery = '';
    gridOptions: any = null;
    gridApi: any = null;
    columnApi: any = null;
    columnDefs: any = null;
    gridColumnApi: any = null;
    maxPageNumbers = 7;
    totalRows = 0;
    totalPages: number = 0;
    currentPage = 1;
    order: OrderGetByIdResult = new OrderGetByIdResult();
    isLogisticManager: Boolean = false;

    settingColumnConfig: SettingColumnResult = new SettingColumnResult();
    defaultSettingColumns: any = [];
    wasSidebarOpen: any = null;

    defaultColDef = {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        filter: true,
    };

    // search
    attributeOptions: FilterAttributeResult[] = [];
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ['normalizedName'];
    selectFieldRequired: string[] = ['id', 'code', 'fromOrderId', 'fromOrderCode', 'customerId', 'type', 'deliveryDate'];

    orders: any = [];
    pinnedTopRowData: any = null;
    onRowSelected: any = null;
    selectedIds: Array<string> = [];
    selectedRows: Array<any> = [];

    // Cell Renderer Components
    celRenderComponents: any = {
        CellRendererLink,
        CellRendererStatus,
        CellRendererActions,
        CustomerCellComponent
    };

    frameworkComponents: any = {
        CustomPinnedRowRenderer
    };

    constructor() {
        super();
        this.orderService = new OrderService();
        this.settingColumnService = new SettingColumnService();
        this.loadOptions.sort = [new SortingInfo('createTime')];
        this.userConfigService = new UserConfigService();
    }

    // get isHasRowSelected() {
    //     return this.gridApi && this.gridApi.getSelectedRows().length > 0;
    // }

    get currentUser() {
        return this.oidcUser;
    }

    async beforeMount() {
        this.gridOptions = {
            navigateToNextCell: this.navigateToNextCell,
            localeText: {noRowsToShow: 'Không tìm thấy đơn hàng nào.'},
        };

        this.getDefaultColumnSetting();
        this.initial();

        this.onRowSelected = (params: any) => {
            let data = params.data;

            if (params.node.selected) {
                this.selectedIds.push(data.id);
                this.selectedRows.push(data);
            } else {
                this.selectedIds = _filter(this.selectedIds, (item: any) => {
                    return item !== data.id;
                });

                this.selectedRows = _filter(this.selectedRows, (item: any) => {
                    return item.id !== data.id;
                });
            }
        };

        let result = await this.userConfigService.getUserConfig(this.currentUser.userId);

        if (result.code) {
            let userConfig = result.data;

            if (userConfig)
                this.isLogisticManager = userConfig.logisticManager
        }
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;

        // if (this.$vs.rtl) {
        //     const header = this.agGridTable.$el.querySelector('.ag-header-container');
        //     header.style.left = '-' + String(Number(header.style.transform.slice(11, -3)) + 9) + 'px';
        // }

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }

    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    initial() {
        this.getColumnDefaultApi();

        this.settingColumnService.getByModuleName('Order').then((result: ActionResult<SettingColumnResult>) => {
            this.settingColumnConfig = result.data as SettingColumnResult;
            if (!this.settingColumnConfig) {
                this.settingColumnConfig = new SettingColumnResult('Order');
                this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
            }

            this.getFieldSelectFromResult();

            const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
            if (!filterState || filterState.searchValue !== null) {
                this.search();
            }

            if (this.settingColumnsRef)
                this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);
        });
    }

    exportExcel() {
        this.orderService.exportExcel(this.loadOptions);
    }

    async onRowDoubleClick(params: any) {
        if (params.rowPinned) {
            return;
        }

        let result = await this.orderService.getById(params.data.id);

        if (result.code > 0) {
            this.order = result.data as OrderGetByIdResult;
        }

        this.orderDetailSideBar.open();
    }

    navigateToNextCell(params: any) {
        let previousCell = params.previousCellPosition;
        let suggestedNextCell = params.nextCellPosition;

        let KEY_UP = 38;
        let KEY_DOWN = 40;
        let KEY_LEFT = 37;
        let KEY_RIGHT = 39;

        switch (params.key) {
            case KEY_DOWN:
                previousCell = params.previousCellPosition;
                // set selected cell on current cell + 1
                this.gridOptions.api.forEachNode(function (node: any) {
                    if (previousCell.rowIndex + 1 === node.rowIndex) {
                        node.setSelected(true, true);
                    }
                });
                return suggestedNextCell;
            case KEY_UP:
                previousCell = params.previousCellPosition;
                // set selected cell on current cell - 1
                this.gridOptions.api.forEachNode(function (node: any) {
                    if (previousCell.rowIndex - 1 === node.rowIndex) {
                        node.setSelected(true, true);
                    }
                });
                return suggestedNextCell;
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always one of the 4 keys above";
        }
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }

        this.search();
    }

    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    createData(contacts: any) {
        let result: any = [];
        let sumTotalPrice = contacts.reduce((acc: any, curr: any) => acc + parseInt(curr.payAmount), 0);
        let sumPaidPrice = contacts.reduce((acc: any, curr: any) => acc + parseInt(curr.paidPrice), 0);
        let sumRemainPrice = contacts.reduce((acc: any, curr: any) => acc + parseInt(curr.remainPrice), 0);

        result.push({
            payAmount: sumTotalPrice,
            paidPrice: sumPaidPrice,
            remainPrice: sumRemainPrice,
        });

        return result;
    }

    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search(false);
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;
        this.setFilterState(this.currentPage, pageSize, this.loadOptions);

        this.search(false);
    }

    private renderAttributeOptions() {
        this.orderService.getAttributeResult().then((result: FilterAttributeResult[]) => {
            if (result && result.length > 0) {
                result = _filter(result, (item: FilterAttributeResult)=>{
                    return item.attributeId !== "TruckId";
                });

                _each(result, (item: FilterAttributeResult) => {
                    switch (item.attributeId) {
                        case 'DiscountType':
                            item.attributeOptions = [{
                                id: DiscountType.Percent,
                                name: 'Phần trăm',
                                data: null
                            }, {
                                id: DiscountType.Money,
                                name: 'Tiền',
                                data: null
                            }];
                            item.type = AttributeType.CheckList;
                            break;
                        case 'Status':
                            item.attributeOptions = [
                                {
                                    id: Status.Draft,
                                    name: new OptionOrderStatus(Status.Draft).name,
                                    data: null
                                }, {
                                    id: Status.Ordered,
                                    name: new OptionOrderStatus(Status.Ordered).name,
                                    data: null
                                }, {
                                    id: Status.GoodsIssued,
                                    name: new OptionOrderStatus(Status.GoodsIssued).name,
                                    data: null
                                }, {
                                    id: Status.Delivering,
                                    name: new OptionOrderStatus(Status.Delivering).name,
                                    data: null
                                }, {
                                    id: Status.Delivered,
                                    name: new OptionOrderStatus(Status.Delivered).name,
                                    data: null
                                }, {
                                    id: Status.WaitingForReturnAndExchange,
                                    name: new OptionOrderStatus(Status.WaitingForReturnAndExchange).name,
                                    data: null
                                }, {
                                    id: Status.GoodsNotDelivered,
                                    name: new OptionOrderStatus(Status.GoodsNotDelivered).name,
                                    data: null
                                }, {
                                    id: Status.Canceled,
                                    name: new OptionOrderStatus(Status.Canceled).name,
                                    data: null
                                }, {
                                    id: Status.TotalOrder,
                                    name: new OptionOrderStatus(Status.TotalOrder).name,
                                    data: null
                                }
                            ];
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;
                        case 'SalerId':
                        case 'CreatorId':
                            item.urlSuggestion = `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;
                        case 'BranchId':
                            item.urlSuggestion = `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.Branch}`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;
                        case 'DeliveryDateFrom': {
                            item.attributeId = 'DeliveryDate.To';
                            break;
                        }

                        case 'ManagerId':
                            item.urlSuggestion = `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        case 'SalesChannelId':
                            item.urlSuggestion = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customer-groups/activated`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        case 'StoreId':
                            item.urlSuggestion = `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.Inventory}`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        case 'CustomerId':
                            item.urlSuggestion = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        case 'ProductGroupId':
                            item.urlSuggestion = `${process.env.VUE_APP_PRODUCT_URL}api/v1/product-group/suggestion`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        case 'ProductId':
                            item.urlSuggestion = `${process.env.VUE_APP_PRODUCT_URL}api/v1/products/suggestions`;
                            item.type = AttributeType.DropdownList;
                            item.isMultiple = true;
                            break;

                        default:
                            break;
                    }
                })

                this.attributeOptions = result;
            }
        });
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === 'desc')];
            this.search(false);
        }
    }

    print() {
        // const orderIds = this.gridApi.getSelectedRows().map((order: any) => {
        //     return order.id;
        // });
        this.orderPrint.print(this.selectedIds);
    }

    private async search(isGetTotal = true) {
        this.openLoading(true);

        const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;
        this.loadOptions.remoteSelect = false;
        this.selectedIds = [];

        this.orderService.search(this.loadOptions).finally(() => {
            this.openLoading(false);
        }).then((result: LoadResult) => {
            this.orders = result.data;
            // this.totalRows = result.totalCount;
            // this.setTotalPage(result.totalCount);

            setTimeout(() => {
                if (isGetTotal) {
                    this.orderService.countByQuery(this.loadOptions).then((result: number) => {
                        this.setTotalPage(result);
                    })
                } else {
                    this.setTotalPage(this.totalRows);
                }
            }, 200)

            this.pinnedTopRowData = this.createData(this.orders);
        })
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    private getFieldSelectFromResult() {
        const columnSelect = _filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        })

        const fieldSelect = _map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== 'actions' && idx !== 'selection';
        });
    }

    private getDefaultColumnSetting() {
        this.defaultSettingColumns = [
            new SettingColumnSelect('Actions', 'actions', true),
            new SettingColumnSelect('Selection', 'selection', true),
            new SettingColumnSelect('Code', 'code', true),
            new SettingColumnSelect('CreateTime', 'createTime', true),
            new SettingColumnSelect('OrderDate', 'orderDate', true),
            new SettingColumnSelect('DeliveryDate', 'deliveryDate', false),
            new SettingColumnSelect('CustomerName', 'customerName', true),
            new SettingColumnSelect('Phone', 'phone', true),
            new SettingColumnSelect('Address', 'address', true),
            new SettingColumnSelect('TotalPrice', 'payAmount', true),
            new SettingColumnSelect('PaidPrice', 'paidPrice', true),
            new SettingColumnSelect('RemainPrice', 'remainPrice', true),
            new SettingColumnSelect('Status', 'status', true),
            new SettingColumnSelect('Note', 'note', false),
            new SettingColumnSelect('Showroom', 'branchName', false),
            new SettingColumnSelect('NoteInternal', 'noteInternal', false),
            new SettingColumnSelect('CreatorFullName', 'creatorFullName', true),
            new SettingColumnSelect('TotalProduct', 'totalProduct', false),
            new SettingColumnSelect('TotalActualExport', 'totalActualExport', true),
            new SettingColumnSelect('Người quản lý KH', 'managerCustomerName', true),
            new SettingColumnSelect('Kênh bán hàng', 'salesChannelName', true),
            new SettingColumnSelect('Đồng Bộ IG', 'erpCode', false),
            new SettingColumnSelect('Ghi chú giao vận', 'noteShipment', true),
            new SettingColumnSelect('Ý kiến của kinh doanh', 'noteBusiness', false),
        ]
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: '',
                field: 'actions',
                width: 50,
                cellRendererFramework: CellRendererActions,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellStyle: {textAlign: "center"},
                filter: false,
                pinned: 'left',
                suppressSizeToFit: true,
            },
            {
                headerName: '',
                field: 'selection',
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: 'left',
                suppressSizeToFit: true,
                //pinned: 'left'
            },
            {
                headerName: this.$t('Code'),
                field: 'code',
                width: 220,
                filter: true,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRendererFramework: CellRendererLink,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                pinnedRowCellRendererParams: {style: {color: "blue"}}
            },
            {
                headerName: this.$t('CreateTime'),
                field: 'createTime',
                filter: true,
                width: 200,
                cellRenderer: (data: any) => {
                    if (data.value) {
                        return `<i class="text-sm">` + moment(data.value).format('DD-MM-YYYY HH:mm') + `</i>`
                    } else {
                        return '';
                    }
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: this.$t('OrderDate'),
                field: 'orderDate',
                filter: true,
                width: 200,
                cellRenderer: (data: any) => {
                    if (data.value) {
                        return moment(data.value).format('DD-MM-YYYY HH:mm')
                    } else {
                        return '';
                    }
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: this.$t('DeliveryDate'),
                field: 'deliveryDate',
                filter: true,
                width: 280,
                editable: (params: any) => {
                    if (params.node.rowPinned) {
                        return;
                    }

                    return this.orderService.isEdit(params.data.status);
                },
                onCellValueChanged: (params: any) => {
                    if (!params.data.isChangeDeliveryDate)
                        return;

                    let newValue = params.newValue ? params.newValue : "";

                    if (!newValue.from || !newValue.to)
                        return;

                    let id = params.data.id;
                    let code = params.data.code;

                    this.updateDeliveryDate(id, code, moment(newValue.from).format(), moment(newValue.to).format());
                    params.data.isChangeDeliveryDate =  false;
                },
                cellRenderer: (data: any) => {
                    if (data.value) {
                        if (data.value.from === data.value.to)
                            return `<i class="text-sm">` + moment(data.value.to).format('DD-MM-YYYY HH:mm') + `</i>`;
                        return `<i class="text-sm">` + moment(data.value.from).format('DD-MM-YYYY HH:mm') + ' - ' + moment(data.value.to).format('DD-MM-YYYY HH:mm') + `</i>`
                    } else {
                        return '';
                    }
                },
                cellEditorFramework: CellEditorDeliveryDate,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: this.$t('CustomerName'),
                field: 'customerName',
                filter: true,
                width: 250,
                cellRendererFramework: CustomerCellComponent,
            },
            {
                headerName: this.$t('Phone'),
                field: 'phone',
                filter: true,
                width: 150,
            },
            {
                headerName: this.$t('Address'),
                field: 'address',
                filter: true,
                width: 150,
            },
            {
                headerName: this.$t('TotalPrice'),
                field: 'payAmount',
                filter: true,
                width: 150,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: this.$t('PaidPrice'),
                field: 'paidPrice',
                filter: true,
                width: 150,
                cellStyle: {textAlign: "right"},
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
            },
            {
                headerName: this.$t('RemainPrice'),
                field: 'remainPrice',
                filter: true,
                width: 150,
                cellStyle: {textAlign: "right"},
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
            },
            {
                headerName: this.$t('Status'),
                field: 'status',
                filter: true,
                width: 150,
                cellRendererFramework: CellRendererStatus,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            {
                headerName: this.$t('Note'),
                field: 'note',
                filter: true,
                width: 150,
            },
            {
                headerName: 'Showroom',
                field: 'branchName',
                filter: true,
                width: 150,
            },
            {
                headerName: this.$t('NoteInternal'),
                field: 'noteInternal',
                filter: true,
                width: 160,
            },
            {
                headerName: this.$t('CreatorFullName'),
                field: 'creatorFullName',
                filter: true,
                width: 150,
            },
            {
                headerName: this.$t('TotalProduct'),
                field: 'totalProduct',
                filter: true,
                width: 120,
                cellStyle: {textAlign: "right"},
            },
            {
                headerName: this.$t('TotalActualExport'),
                field: 'totalActualExport',
                filter: true,
                width: 130,
                cellStyle: {textAlign: "right"},
            },
            {
                headerName: 'Người quản lý KH',
                field: 'managerCustomerName',
                filter: true,
                width: 200,
            },
            {
                headerName: 'Kênh bán hàng',
                field: 'salesChannelName',
                filter: true,
                width: 200,
            },
            {
                headerName: 'Đồng bộ IG',
                field: 'erpCode',
                filter: false,
                width: 100,
                cellRenderer: (data: any) => {
                    if (data.value === undefined)
                        return;

                    return data.value == null || data.value == "" ? "Thất bại" : "Thành công";
                },
            },
            {
                headerName: "Ghi chú giao vận",
                field: 'noteShipment',
                filter: true,
                editable: (params: any) => {
                    if (params.node.rowPinned) {
                        return;
                    }

                    return this.isLogisticManager;
                },
                width: 200,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    // maxLength: '300',
                    cols: '40',
                    rows: '4'
                },
                onCellValueChanged: (params: any) => {
                    let newValue = params.newValue ? params.newValue : "";
                    let oldValue = params.oldValue ? params.oldValue : "";

                    if (newValue !== oldValue) {
                        let id = params.data.id;
                        let code = params.data.code;

                        if (!id)
                            return;

                        // Nếu không phải user Điều vận.
                        if (!this.isLogisticManager) {
                            this.$vs.notify({
                                title: '',
                                text: `Không thể cập nhật Ghi chú giao vận của đơn hàng ${code} do không phải người dùng Điều vận.`,
                                color: "danger"
                            });

                            let field = params.colDef.field;
                            params.node.data[field] = params.oldValue;

                            // Prevent the onCellValueChanged event from having to be called again.
                            params.api.refreshCells({rowNodes: [params.node], columns: [field]});

                            return;
                        }

                        this.updateNoteShipment(id, code, newValue);
                    }
                }
            },
            {
                headerName: 'Ý kiến của kinh doanh',
                field: 'noteBusiness',
                filter: true,
                width: 200,
                editable: (params: any) => {
                    return !params.node.rowPinned;
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    // maxLength: '300',
                    cols: '40',
                    rows: '4'
                },
                onCellValueChanged: (params: any) => {
                    let newValue = params.newValue ? params.newValue : "";
                    let oldValue = params.oldValue ? params.oldValue : "";

                    if (newValue !== oldValue) {
                        let id = params.data.id;
                        let code = params.data.code;

                        if (!id)
                            return;

                        this.updateNoteBusiness(id, code, newValue);
                    }
                }
            }
        ];
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch('filter/setFilterState', filterState);
    }

    openExportOptions() {
        this.exportOptions.open();
    }

    async onExportFile(exportType: ExportType) {
        let loadOptions = _cloneDeep(this.loadOptions) as any;
        loadOptions.exportType = exportType;

        if (exportType === ExportType.Selected) {
            loadOptions.selectedIds = this.selectedIds;
        }

        this.openLoading(true);
        await this.orderService.exportExcel(loadOptions);
        this.openLoading(false);
    }

    // TODO: chưa check trên server.
    async updateNoteShipment(id: string, code: string, note: string) {
        try {
            let result = await this.orderService.updateNoteShipment(id, note);

            if (result.code > 0) {
                this.$vs.notify({
                    title: '',
                    text: `Cập nhật Ghi chú điều vận của đơn hàng ${code} thành công!`,
                    color: "success"
                });
            }

        } catch (error) {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });
        }
    }

    openGoodsIssuedUpdateStatus() {
        let items = _filter(this.selectedRows, (item) => {
            return item.status === Status.GoodsIssued;
        });

        if (items && items.length === 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng chọn đơn hàng trạng thái Đã xuất kho!",
                color: "danger"
            });

            return;
        }

        this.updateStatus.open();
    }

    onOrderGoodsIssuedUpdateStatus(orderIds: string[], status: Status) {
        _each(orderIds, (orderId: string) => {
            let orderItem = _find(this.orders, (order) => order.id === orderId);

            if (orderItem)
                orderItem.status = status;
        })

        this.gridApi.refreshCells();
        this.gridApi.deselectAll();
    }

    async updateNoteBusiness(id: string, code: string, note: string) {
        try {
            this.openLoading(true);
            let result = await this.orderService.updateNoteBusiness(id, note);
            this.openLoading(false);

            if (result.code > 0) {
                this.$vs.notify({
                    title: '',
                    text: `Cập nhật Ý kiến của kinh doanh đơn hàng ${code} thành công!`,
                    color: "success"
                });
            }

        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });
        }
    }

    async updateDeliveryDate(id: string, code: string, fromDate: string, toDate: string) {
        try {
            this.openLoading(true);
            let result = await this.orderService.updateDeliveryDate(id, fromDate, toDate);
            this.openLoading(false);

            if (result.code > 0) {
                this.$vs.notify({
                    title: '',
                    text: `Cập nhật Ngày giao hàng đơn hàng ${code} thành công!`,
                    color: "success"
                });
            }

        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });
        }
    }
}
